<template>
  <div id="app" :class="[none, active && 'block', filter && 'filters']" @scroll.passive="scroll">
    <Header />
    <transition name="fadeLeft" mode="out-in" tag="div">
      <Menu v-show="active" ref="menu" transition="fadeLeft" />
    </transition>
    <transition name="fade" mode="out-in" tag="div" class="main-content">
      <router-view />
    </transition>
    <!-- <Footer /> -->
  </div>
</template>
<script>

import Bus from '@/utils/Bus'
import Header from '@/widgets/Header'
import Menu from '@/widgets/Menu'
// import Footer from '@/widgets/Footer'

export default {
  components: {
    Header,
    Menu,
    // Footer
  },
  name: 'App',
  data() {
    return {
      active: false,
      showm: false,
      scroll: true,
      filter: false
    }
  },
  computed: {
    none() {
      if (this.$route.path === '/') return 'home'
      if (this.$route.path === '/valuation') return 'home'
      if (this.$route.path === '/faq') return 'home'

      if (this.$route.name === 'DevelopmentsFilter') return 'developments'
      if (this.$route.name === 'Developments') return 'developments'
      if (this.$route.name === 'Development') return 'development'
      return 'common'
    }
  },
  mounted() {
    Bus.$on('showMenu', this.toggle)
    Bus.$on('swichWindow', this.toggleModal)
    // Bus.$on('toggleFilter', this.togFilter)
    this.$store.dispatch('getAllDevelopments')
  },
  methods: {
    toggle() {
      this.active = !this.active
      this.toggleScroll()
      if (this.active) {
        this.$refs.menu.$refs.menuList.style.position = 'fixed'
        this.$refs.menu.$refs.menuList.style.width = '100%'
        this.$refs.menu.$refs.menuList.style.maxWidth = '29em'
      } else {
        this.$refs.menu.$refs.menuList.style.position = 'inherit'
      }
    },

    toggleModal() {
      this.showm = !this.showm
      this.toggleScroll()
    },
    toggleScroll() {
      // if(this.scroll) document.body.style.overflow = 'hidden';
      // if(this.scroll) document.body.style.webkitOverflowScrolling = 'touch';
      // else {
      //   document.body.style.overflow = 'auto';
      //   document.body.style.webkitOverflowScrolling = 'auto';
      // }
      // this.scroll = !this.scroll;
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/base";
@import "assets/scss/global";

#app {
  @include mainCenter;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

#app.filters {
  height: 100vh;
  overflow-y: hidden;
}

#app.development {
  background-color: var(--main);
  transition: all .25s;
}

#app.developments {
  background-color: var(--main);
  transition: all .25s;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .25s;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all .25s;
}

#app.home {
  padding-top: 2.5em;
}
</style>
