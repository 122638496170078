import Vue from "vue";
import Vuex from "vuex";

import { getProperties, getDevelopments, getPropertyById } from "@/api";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    searchKey: "",
    filter: {
      item: 0,
      order: "",
      zones: [],
      propTypes: [],
      scope: [null, null],
      view: true,
      operation: "",
    },
    zones: [
      {
        name: "Todas",
        key: 0,
        state: true,
      },
    ],
    types: [
      {
        name: "Todas",
        key: 0,
        state: true,
      },
    ],
    loading: false,
    properties: [],
    developments: [],
  },
  getters: {
    loading: (state) => state.loading,
    isZonesLoaded: (state) => {
      return state.zones.length > 1;
    },
    properties: (state) => {
      let filteredProperties;
      const filZon = state.filter.zones;
      const filKey = state.searchKey;
      const filTypes = state.filter.propTypes;
      const filScope = state.filter.scope;
      const order = state.filter.order;
      const hasScope = filScope[0] !== null || filScope[1] !== null;
      const hasOperation = !!state.filter.operation;
      if (
        !filZon.length &&
        !filKey &&
        !filTypes.length &&
        !order &&
        !hasScope &&
        !hasOperation
      )
        return state.properties;

      filteredProperties = [...state.properties];
      if (filKey) {
        const splitted = filKey.split(" ").map((s) => s.toLowerCase().trim());
        filteredProperties = filteredProperties.filter((p) => {
          const fullAddress =
            p.location.street +
            p.location.city +
            p.location.state +
            p.type.name;
          return splitted.every((s) =>
            fullAddress.toLowerCase().trim().includes(s)
          );
        });
      }

      if (filZon.length) {
        filteredProperties = filteredProperties.filter((p) =>
          filZon.includes(p.location.city)
        );
      }

      if (filTypes.length) {
        filteredProperties = filteredProperties.filter((p) =>
          filTypes.includes(p.type.id)
        );
      }

      const operation = state.filter.operation.toLowerCase();
      let key = "salePrice";
      let isForKey = "isForSale";
      if (hasOperation) {
        key = operation == "alquiler" ? "rentPrice" : "salePrice";
        isForKey = operation == "alquiler" ? "isForRent" : "isForSale";
      }

      if (hasScope) {
        if (filScope[0] > filScope[1]) filScope.reverse();
        if (filScope[0]) {
          filteredProperties = filteredProperties.filter(
            (p) => p[isForKey] && p[key] > filScope[0]
          );
        }

        if (filScope[1]) {
          filteredProperties = filteredProperties.filter(
            (p) => p[isForKey] && p[key] < filScope[1]
          );
        }
      }

      if (hasOperation) {
        filteredProperties = filteredProperties.filter((p) => {
          if (operation === "all") return true;
          else if (operation == "venta") return p.isForSale;
          else if (operation == "alquiler") return p.isForRent;
          else return false;
        });
      }

      if (order) {
        filteredProperties.sort((a, b) => {
          if (!a[isForKey] || !a[key]) return -1;
          return a[key] - b[key];
        });
        if (order === "des") filteredProperties.reverse();
      }

      return filteredProperties;
    },
    allProperties: (state) => {
      return state.properties;
    },
    property(state) {
      return (id) => state.properties.find((x) => x.id === id);
    },
    developments(state) {
      return state.developments;
    },
    propertiesInDev(state) {
      return (id) => {
        return state.properties.filter((x) => id === x.development_id);
      };
    },
    development(state, getters) {
      return (id) => {
        const development = {
          ...getters.developments.find((x) => x.id === id),
        };
        development.properties = getters.propertiesInDev(development.id_rel);
        development.units = development.properties.length;
        return development;
      };
    },
    filter(state) {
      return state.filter;
    },
    types(state) {
      return state.types;
    },
    zones(state) {
      return state.zones;
    },
  },
  actions: {
    async getAllProperties({ commit, state }) {
      commit("setLoading", true);
      commit("setProperties", []);
      const properties = await getProperties();
      commit("setProperties", properties);
      commit("SET_ZONES", properties);
      commit("SET_TYPES", properties);
      commit("setLoading", false);
    },
    async getPropertyById({ state, commit }, id) {
      commit("setLoading", true);
      const property = await getPropertyById(id);
      commit("setProperties", [property]);
      commit("setLoading", false);
    },
    async getAllDevelopments({ commit }) {
      const developments = await getDevelopments();
      commit("setDevelopments", developments);
    },
    setSearch({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_SEARCH", payload);
        resolve("ok");
      });
    },
    setItemProps({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_ITEM_PROPS", payload);
        resolve();
      });
    },
    searchHasZone(state) {
      const dep = (text) =>
        text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

      return dep(state.state.searchKey)
        .split(" ")
        .filter((k) => k.length > 3)
        .map((k) =>
          state.state.zones.find(
            (z) => dep(z.name).split(" ").includes(k) || false
          )
        );
    },
    setFilter({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_FILTER", payload);
        resolve("ok");
      });
    },
    resetLists({ commit }) {
      return new Promise((resolve) => {
        commit("RESET_LISTS");
        resolve("ok");
      });
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setProperties(state, properties) {
      state.properties = properties;
    },
    setDevelopments(state, developments) {
      state.developments = developments;
    },
    SET_ZONES(state, properties) {
      const allZones = [...state.zones];
      properties.forEach((property) => {
        const location = {
          key: property.location.state,
          name: property.location.state,
          state: false,
        };

        const foundZone = allZones.find((z) => z.key === location.key);

        if (!foundZone) {
          allZones.unshift(location);
        }

        state.zones = allZones;
      });
    },
    SET_TYPES(state, properties) {
      const allTypes = [...state.types];
      properties.forEach((property) => {
        if (property.type) {
          const type = {
            key: property.type.id,
            name: property.type.name,
            state: false,
          };

          const foundTypes = allTypes.find((t) => t.key == type.key);

          if (!foundTypes) {
            allTypes.unshift(type);
          }
        }
      });
      state.types = allTypes;
    },
    SET_SEARCH(state, key) {
      state.searchKey = key;
    },
    SET_ITEM_PROPS(state, item) {
      state.filter.item = item;
    },
    SET_FILTER(state, filter) {
      state.filter = filter;
    },
    RESET_LISTS(state) {
      state.zones.map((z) => {
        if (z.key) z.state = false;
      });
      state.types.map((t) => {
        if (t.key) t.state = false;
      });
    },
  },
});

export default store;
